import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  setDoc,
} from "firebase/firestore";

export const streamerService = {
  // Creates a new Twitch VOD entry in the "streams" collection.
  async createTwitchVod({
    userId,
    faceitNickname,
    streamerId,
    twitchUrl,
    matchId,
    streamUrl,
    matchDetails, // new parameter to store match details
  }) {
    try {
      const newStream = {
        matchroomId: matchId,
        streamUrl: streamUrl,
        faceitNickname,
        streamerId,
        twitchUrl,
        userId,
        createdAt: new Date().toISOString(),
        matchDetails, // store the match details directly as an object
      };
      const docRef = await addDoc(collection(db, "streams"), newStream);
      return docRef.id;
    } catch (error) {
      console.error("Error creating Twitch VOD:", error);
      throw error;
    }
  },

  async getStream(streamId) {
    try {
      const streamRef = doc(db, "streams", streamId);
      const streamSnap = await getDoc(streamRef);
      if (streamSnap.exists()) {
        return { id: streamSnap.id, ...streamSnap.data() };
      }
      return null;
    } catch (error) {
      console.error("Error fetching stream:", error);
      throw error;
    }
  },

  async getUserStreams(userId) {
    try {
      const q = query(collection(db, "streams"), where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error fetching user streams:", error);
      throw error;
    }
  },

  async updateStream(streamId, updateData) {
    try {
      const streamRef = doc(db, "streams", streamId);
      await updateDoc(streamRef, updateData);
    } catch (error) {
      console.error("Error updating stream:", error);
      throw error;
    }
  },

  async deleteStream(streamId) {
    try {
      await deleteDoc(doc(db, "streams", streamId));
    } catch (error) {
      console.error("Error deleting stream:", error);
      throw error;
    }
  },

  // Saves a nickname in the "streamers" collection (legacy functionality)
  async saveNickname(userId, nickname) {
    try {
      await setDoc(
        doc(db, "streamers", userId),
        {
          nickname,
          userId,
          updatedAt: new Date().toISOString(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving nickname:", error);
      throw error;
    }
  },

  // Retrieve stored user info from "userInfo" collection
  async getUserInfo(userId) {
    try {
      const docRef = doc(db, "userInfo", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { userId, ...docSnap.data() };
      }
      return null;
    } catch (error) {
      console.error("Error fetching user info:", error);
      throw error;
    }
  },

  // Save/update user info with faceitUsername, twitchUsername and updatedAt timestamp
  async saveUserInfo({ userId, faceitUsername, twitchUsername }) {
    try {
      const newInfo = {
        faceitUsername,
        twitchUsername,
        updatedAt: new Date().toISOString(),
        userId,
      };
      await setDoc(doc(db, "userInfo", userId), newInfo, { merge: true });
      return newInfo;
    } catch (error) {
      console.error("Error saving user info:", error);
      throw error;
    }
  },

  // NEW: Fetch streams for a list of match IDs.
  // Since Firestore's "in" operator is limited to 10 values, we split the matchIds into chunks.
  async getStreamsForMatches(matchIds) {
    try {
      if (!matchIds || matchIds.length === 0) return [];
      const chunks = [];
      for (let i = 0; i < matchIds.length; i += 10) {
        chunks.push(matchIds.slice(i, i + 10));
      }
      let streams = [];
      for (const chunk of chunks) {
        const q = query(
          collection(db, "streams"),
          where("matchroomId", "in", chunk)
        );
        const querySnapshot = await getDocs(q);
        streams = streams.concat(
          querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
      return streams;
    } catch (error) {
      console.error("Error fetching streams for matches:", error);
      throw error;
    }
  },
};
