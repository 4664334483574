import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  arrayUnion,
  arrayRemove,
  orderBy,
  limit as limitFirestore,
} from "firebase/firestore";

export const leagueService = {
  async createLeague(userId, leagueData) {
    try {
      const docRef = await addDoc(collection(db, "leagues"), {
        ...leagueData,
        userId,
        players: [],
        createdAt: new Date().toISOString(),
      });
      return docRef.id;
    } catch (error) {
      console.error("Error creating league:", error);
      throw error;
    }
  },

  async getRecentLeagues(limit = 10) {
    try {
      const q = query(
        collection(db, "leagues"),
        orderBy("createdAt", "desc"),
        limitFirestore(limit)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error fetching recent leagues:", error);
      throw error;
    }
  },

  async getLeague(leagueId) {
    try {
      const leagueRef = doc(db, "leagues", leagueId);
      const leagueSnap = await getDoc(leagueRef);

      if (leagueSnap.exists()) {
        return {
          id: leagueSnap.id,
          ...leagueSnap.data(),
        };
      }
      return null;
    } catch (error) {
      console.error("Error fetching league:", error);
      throw error;
    }
  },

  async getUserLeagues(userId) {
    try {
      const q = query(collection(db, "leagues"), where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error fetching leagues:", error);
      throw error;
    }
  },

  async updateLeague(leagueId, updateData) {
    try {
      const leagueRef = doc(db, "leagues", leagueId);
      await updateDoc(leagueRef, updateData);
    } catch (error) {
      console.error("Error updating league:", error);
      throw error;
    }
  },

  async deleteLeague(leagueId) {
    try {
      await deleteDoc(doc(db, "leagues", leagueId));
    } catch (error) {
      console.error("Error deleting league:", error);
      throw error;
    }
  },

  async addPlayer(leagueId, player) {
    try {
      const leagueRef = doc(db, "leagues", leagueId);
      await updateDoc(leagueRef, {
        players: arrayUnion(player),
      });
    } catch (error) {
      console.error("Error adding player:", error);
      throw error;
    }
  },

  async removePlayer(leagueId, player) {
    try {
      const leagueRef = doc(db, "leagues", leagueId);
      await updateDoc(leagueRef, {
        players: arrayRemove(player),
      });
    } catch (error) {
      console.error("Error removing player:", error);
      throw error;
    }
  },

  async updatePrizes(leagueId, prizes) {
    try {
      const leagueRef = doc(db, "leagues", leagueId);
      await updateDoc(leagueRef, { prizes });
      return await this.getLeague(leagueId);
    } catch (error) {
      console.error("Error updating prizes:", error);
      throw error;
    }
  },

  async updateGayIndex(leagueId, nickname, gayIndex) {
    try {
      const leagueRef = doc(db, "leagues", leagueId);
      await updateDoc(leagueRef, {
        [`gayIndices.${nickname}`]: gayIndex,
      });
    } catch (error) {
      console.error("Error updating gay index:", error);
      throw error;
    }
  },

  // New method to search leagues by name
  async searchLeagueByName(name) {
    try {
      const q = query(collection(db, "leagues"), where("name", "==", name));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        return null;
      }
      // Return the first matched league
      const leagueDoc = querySnapshot.docs[0];
      return { id: leagueDoc.id, ...leagueDoc.data() };
    } catch (error) {
      console.error("Error searching league by name:", error);
      throw error;
    }
  },
};
