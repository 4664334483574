// src/pages/League.js
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { leagueService } from "../services/leagueService";
import { faceitService } from "../services/faceitService";
import {
  getCurrentWeekNumber,
  getWeekDates,
  formatDate,
  formatDateTime,
} from "../utils/dateUtils";
import HallOfShamePlayer from "../components/HallOfShamePlayer";
import LiveGames from "../components/LiveGames";
import gaySound from "../assets/sounds/gay.mp3";
import level1 from "../assets/images/1.png";
import level2 from "../assets/images/2.png";
import level3 from "../assets/images/3.png";
import level4 from "../assets/images/4.png";
import level5 from "../assets/images/5.png";
import level6 from "../assets/images/6.png";
import level7 from "../assets/images/7.png";
import level8 from "../assets/images/8.png";
import level9 from "../assets/images/9.png";
import level10 from "../assets/images/10.png";
import Header from "../components/Header";

const levelImages = {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
  7: level7,
  8: level8,
  9: level9,
  10: level10,
};

function League() {
  const { id } = useParams();
  const currentYear = new Date().getFullYear();
  const currentWeek = getCurrentWeekNumber();
  const [activeTab, setActiveTab] = useState("rankings");
  const [league, setLeague] = useState(null);
  const [playersData, setPlayersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedWeek, setSelectedWeek] = useState(currentWeek);
  const [weekDates, setWeekDates] = useState(
    getWeekDates(currentWeek, currentYear)
  );
  const [gayIndices, setGayIndices] = useState({});
  const [cooldowns, setCooldowns] = useState({});

  // Audio element
  const audioRef = useRef(new Audio(gaySound));
  const [isPlaying, setIsPlaying] = useState(false);

  // Favorite functionality
  const [isFavorite, setIsFavorite] = useState(false);

  // Mobile menu state
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Generate weeks array for dropdown (only up to current week)
  const weeks = Array.from({ length: currentWeek }, (_, i) => ({
    week: i + 1,
    label: `Week ${i + 1}`,
  })).reverse();

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem("favoriteLeagues")) || [];
    setIsFavorite(favorites.includes(id));
  }, [id]);

  const toggleFavorite = () => {
    const favorites = JSON.parse(localStorage.getItem("favoriteLeagues")) || [];
    if (favorites.includes(id)) {
      const newFavorites = favorites.filter((favId) => favId !== id);
      localStorage.setItem("favoriteLeagues", JSON.stringify(newFavorites));
      setIsFavorite(false);
    } else {
      favorites.push(id);
      localStorage.setItem("favoriteLeagues", JSON.stringify(favorites));
      setIsFavorite(true);
    }
  };

  useEffect(() => {
    const loadGayIndices = async () => {
      try {
        const leagueData = await leagueService.getLeague(id);
        if (leagueData?.gayIndices) {
          setGayIndices(leagueData.gayIndices);
        }
      } catch (error) {
        console.error("Error fetching gay indices:", error);
      }
    };

    loadGayIndices();
  }, [id]);

  const handleGayButton = async (nickname) => {
    const now = Date.now();
    const lastPressed = cooldowns[nickname] || 0;

    // Check if 30 minutes have passed
    if (now - lastPressed < 30 * 60 * 1000) {
      const remainingMinutes = Math.ceil(
        (30 * 60 * 1000 - (now - lastPressed)) / 60000
      );
      alert(`You can press again in ${remainingMinutes} minutes`);
      return;
    }

    // Play sound
    try {
      if (!isPlaying) {
        setIsPlaying(true);
        await audioRef.current.play();
        audioRef.current.addEventListener("ended", () => {
          setIsPlaying(false);
        });
      }
    } catch (error) {
      console.log("Audio playback error:", error);
      setIsPlaying(false);
    }

    // Increment gay index directly in Firestore
    try {
      const newGayIndex = (gayIndices[nickname] || 0) + 1;

      // Update the state and Firestore at the same time
      setGayIndices((prev) => ({
        ...prev,
        [nickname]: newGayIndex,
      }));
      setCooldowns((prev) => ({
        ...prev,
        [nickname]: now,
      }));

      // Update Firestore without using localStorage
      await leagueService.updateGayIndex(id, nickname, newGayIndex);
    } catch (error) {
      console.error("Error updating gay index in Firestore:", error);
    }
  };

  const fetchPlayersStats = useCallback(
    async (players) => {
      if (!weekDates) return;

      try {
        // Fetch all player details in parallel
        const playerDetailsArray = await Promise.all(
          players.map(async (nickname) => {
            const playerDetails = await faceitService.getPlayerDetails(
              nickname
            );
            return playerDetails;
          })
        );

        // Fetch all player weekly stats in parallel
        const playersStatsArray = await Promise.all(
          playerDetailsArray.map(async (playerDetails) => {
            if (playerDetails) {
              const weeklyStats = await faceitService.getPlayerHistoryStats(
                playerDetails.player_id,
                weekDates.start,
                weekDates.end
              );

              const cs2Data = playerDetails.games?.cs2 || {};

              return {
                nickname: playerDetails.nickname,
                playerId: playerDetails.player_id,
                elo: cs2Data.faceit_elo || 0,
                weeklyElo: weeklyStats.eloChange || 0,
                level: cs2Data.skill_level || 0,
                matches: weeklyStats.matches || "0",
                winRate: weeklyStats.winRate || "0",
                recentResults: weeklyStats.results || [],
                avatarUrl: playerDetails.avatar || "",
                weeklyWins: weeklyStats.weeklyWins || "0",
                weeklyLosses: weeklyStats.weeklyLosses || "0",
                lastActivity: weeklyStats.lastActivity,
                faceitUrl: playerDetails.faceit_url.replace("{lang}", "en"),
              };
            } else {
              return null;
            }
          })
        );

        // Filter out any null entries
        const validPlayersStats = playersStatsArray.filter(
          (stat) => stat !== null
        );

        // Sort the players based on weeklyElo
        validPlayersStats.sort((a, b) => b.weeklyElo - a.weeklyElo);
        setPlayersData(validPlayersStats);
      } catch (error) {
        console.error("Error fetching players' stats:", error);
      }
    },
    [weekDates]
  );

  const loadLeagueData = useCallback(async () => {
    if (!weekDates) return;

    try {
      const leagueData = await leagueService.getLeague(id);
      setLeague(leagueData);
      if (leagueData?.players) {
        await fetchPlayersStats(leagueData.players);
      }
    } catch (error) {
      console.error("Error loading league:", error);
    } finally {
      setLoading(false);
    }
  }, [weekDates, id, fetchPlayersStats]);

  useEffect(() => {
    const newWeekDates = getWeekDates(selectedWeek, currentYear);
    setWeekDates(newWeekDates);
  }, [selectedWeek, currentYear]);

  useEffect(() => {
    if (weekDates) {
      loadLeagueData();
    }
  }, [weekDates, loadLeagueData]);

  // Load gay indices and cooldowns from localStorage
  useEffect(() => {
    const savedGayIndices = localStorage.getItem("gayIndices");
    const savedCooldowns = localStorage.getItem("cooldowns");

    if (savedGayIndices) {
      setGayIndices(JSON.parse(savedGayIndices));
    }
    if (savedCooldowns) {
      setCooldowns(JSON.parse(savedCooldowns));
    }
  }, []);

  const getPrize = (index) => {
    if (!league?.prizes) return null;
    if (index === 0) return league.prizes.first;
    if (index === 1) return league.prizes.second;
    if (index === 2) return league.prizes.third;
    if (index === playersData.length - 1) return "💀";
    return null;
  };

  const tabs = [
    { id: "rankings", label: "Rankings" },
    { id: "liveGames", label: "Past Games" },
    { id: "hallofshame", label: "Hall of Shame" },
  ];

  // Filter players by nickname based on searchQuery.
  // When searchQuery is empty, all players will be returned.
  const filteredPlayers = playersData.filter((player) =>
    player.nickname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-stone-950 text-white p-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Header */}
      <Header />
      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        {/* Tabs and Mobile Menu */}
        <div className="flex flex-wrap gap-1 md:gap-2 mb-6 mt-4 items-center justify-between">
          {/* Hamburger menu icon - shows on mobile */}
          <div className="flex md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-gray-300 hover:text-white focus:outline-none focus:text-white"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  className={`${isMobileMenuOpen ? "hidden" : "inline-flex"}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={`${isMobileMenuOpen ? "inline-flex" : "hidden"}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Tabs - hidden on mobile */}
          <div className="hidden md:flex flex-wrap gap-1 md:gap-2">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-3 py-2 text-sm md:px-4 md:py-2 rounded-lg transition-colors ${
                  activeTab === tab.id
                    ? "border border-orange-500 text-orange-500"
                    : "bg-gray-800 text-gray-300 hover:bg-gray-700"
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Mobile menu - shows when hamburger is clicked */}
          <div
            className={`${
              isMobileMenuOpen ? "block" : "hidden"
            } md:hidden w-full`}
          >
            <div className="flex flex-col gap-1 mt-2">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => {
                    setActiveTab(tab.id);
                    setIsMobileMenuOpen(false);
                  }}
                  className={`px-3 py-2 text-sm rounded-lg transition-colors ${
                    activeTab === tab.id
                      ? "border border-orange-500 text-orange-500"
                      : "bg-gray-800 text-gray-300 hover:bg-gray-700"
                  }`}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          {/* Filter Input */}
          <div className="hidden md:flex flex-col items-end gap-2 ml-auto">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Filter by nickname..."
              className="px-3 py-2 md:px-4 md:py-2 rounded-lg bg-gray-800 text-white border border-gray-700 focus:outline-none focus:ring-1 focus:ring-gray-100/50"
            />
          </div>
        </div>

        {/* Tab Content */}
        <div className="bg-gray-800 rounded-lg p-4 md:p-6">
          {activeTab === "rankings" && weekDates && (
            <div>
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
                <div className="text-sm text-gray-400">
                  <button onClick={toggleFavorite} className="mr-5 text-2xl">
                    {isFavorite ? "⭐" : "⚝"}
                  </button>
                  {formatDate(weekDates.start)} - {formatDate(weekDates.end)}
                </div>

                <select
                  value={selectedWeek}
                  onChange={(e) => setSelectedWeek(Number(e.target.value))}
                  className="w-full md:w-auto bg-gray-700 text-white px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  {weeks.map((week) => (
                    <option
                      key={week.week}
                      value={week.week}
                      disabled={week.disabled}
                    >
                      {week.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="player-table">
                {/* Table Headers - Hidden on mobile */}
                <div className="hidden md:grid grid-cols-9 gap-4 mb-4 text-gray-400 text-sm font-semibold">
                  <div className="col-span-2">Player</div>
                  <div className="text-center">Gay Index</div>
                  <div className="text-center">ELO</div>
                  <div className="text-center">Weekly Points</div>
                  <div className="text-center">W/L</div>
                  <div className="text-center">Win Rate</div>
                  <div className="text-center">Recent</div>
                  <div className="text-center">Prize</div>
                </div>

                {/* Player Rows */}
                {filteredPlayers.map((player, index) => (
                  <div
                    key={player.nickname}
                    className={`relative flex md:grid md:grid-cols-9 gap-4 py-4 border-t border-gray-700 items-center ${
                      player.matches > 0
                        ? ""
                        : "opacity-50 hover:opacity-100 hover:text-gray-400"
                    }`}
                    title={player.matches > 0 ? "" : "Inactive"}
                  >
                    {/* Mobile Layout */}
                    <div className="md:hidden flex items-center justify-between w-full">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-400 mr-2">{index + 1}</span>
                        <img
                          src={player.avatarUrl}
                          alt=""
                          className="w-8 h-8 rounded-full"
                        />
                        <div>
                          <div className="font-medium">{player.nickname}</div>
                          {player.matches > 0 ? (
                            <div
                              className={`text-sm ${
                                player.weeklyElo > 0
                                  ? "text-green-500"
                                  : player.weeklyElo < 0
                                  ? "text-red-500"
                                  : "text-gray-400"
                              }`}
                            >
                              {player.weeklyElo > 0 ? "+" : ""}
                              {player.weeklyElo} pts
                            </div>
                          ) : (
                            <div className="text-gray-400 text-sm">
                              Inactive
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-xl">{getPrize(index)}</div>
                    </div>

                    {/* Desktop Layout */}
                    <div className="hidden md:flex col-span-2 items-center space-x-2">
                      <span className="text-gray-400 mr-2">{index + 1}</span>
                      <img
                        src={player.avatarUrl}
                        alt=""
                        className="w-8 h-8 rounded-full"
                      />
                      <div className="flex flex-col">
                        <a
                          href={player.faceitUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-semibold text-blue-400 hover:underline"
                        >
                          {player.nickname}
                        </a>
                        <span className="text-xs text-gray-400">
                          Last: {formatDateTime(player.lastActivity)}
                        </span>
                      </div>
                    </div>

                    {player.matches > 0 ? (
                      <>
                        <div className="hidden md:flex items-center justify-center">
                          <button
                            onClick={() => handleGayButton(player.nickname)}
                            className="px-2 py-1 text-sm bg-pink-500 hover:bg-pink-600 rounded"
                          >
                            🔊 ({gayIndices[player.nickname] || 0})
                          </button>
                        </div>
                        <div className="hidden md:flex items-center justify-center space-x-2">
                          <img
                            alt={`Level ${player.level}`}
                            src={levelImages[player.level]}
                            className="w-6 h-6"
                          />
                          <div className="text-sm font-medium">
                            {player.elo}
                          </div>
                        </div>
                        <div
                          className={`text-sm font-medium hidden md:block text-center font-medium ${
                            player.weeklyElo > 0
                              ? "text-green-500"
                              : player.weeklyElo < 0
                              ? "text-red-500"
                              : "text-gray-400"
                          }`}
                        >
                          {player.weeklyElo > 0 ? "+" : ""}
                          {player.weeklyElo}
                        </div>
                        <div className="hidden md:block text-center text-sm font-medium">
                          <span className="text-green-500">
                            {player.weeklyWins}
                          </span>{" "}
                          /{" "}
                          <span className="text-red-500">
                            {player.weeklyLosses}
                          </span>
                        </div>
                        <div className="hidden md:block text-center text-sm font-medium">
                          {player.winRate}%
                        </div>
                        <div className="hidden md:flex flex-col items-center space-y-1">
                          <div className="flex justify-center space-x-1">
                            {player.recentResults
                              .slice(0, 5)
                              .map((result, i) => (
                                <span
                                  key={i}
                                  className={`w-2 h-2 rounded-full ${
                                    result === "1"
                                      ? "bg-green-500"
                                      : "bg-red-500"
                                  }`}
                                />
                              ))}
                          </div>
                          <div className="flex justify-center space-x-1">
                            {player.recentResults
                              .slice(5, 10)
                              .map((result, i) => (
                                <span
                                  key={i + 5}
                                  className={`w-2 h-2 rounded-full ${
                                    result === "1"
                                      ? "bg-green-500"
                                      : "bg-red-500"
                                  }`}
                                />
                              ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-center">-</div>
                        <div className="text-center">-</div>
                        <div className="text-center">-</div>
                        <div className="text-center">-</div>
                        <div className="text-center">-</div>
                        <div className="text-center">-</div>
                      </>
                    )}

                    <div className="hidden md:flex justify-center text-xl">
                      {getPrize(index)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === "liveGames" && (
            <div>
              <LiveGames playersData={filteredPlayers} />
            </div>
          )}

          {activeTab === "history" && (
            <div className="text-center text-gray-400 py-12">
              Match history feature coming soon
            </div>
          )}

          {activeTab === "hallofshame" && (
            <div>
              {playersData.length > 0 && (
                <HallOfShamePlayer
                  player={playersData[playersData.length - 1]}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default League;
