import React, { useState, useEffect } from "react";
import { streamerService } from "../services/streamerService";
import { faceitService } from "../services/faceitService";
import { useAuth } from "../context/AuthContext";

function StreamerModeTab() {
  const { user } = useAuth();
  const [step, setStep] = useState(1); // 1: Matchroom selection; 2: Stream URL input
  const [faceitNickname, setFaceitNickname] = useState("");
  const [twitchNickname, setTwitchNickname] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [games, setGames] = useState([]);
  const [selectedMatchrooms, setSelectedMatchrooms] = useState([]);
  const [streamUrls, setStreamUrls] = useState({});
  const [streamUrlErrors, setStreamUrlErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [existingStreams, setExistingStreams] = useState([]);

  // User info state
  const [userInfo, setUserInfo] = useState(null);
  const [editingUserInfo, setEditingUserInfo] = useState(false);

  // Regex for Twitch URL validation
  const streamUrlRegex = /^https:\/\/www\.twitch\.tv\/videos\/\d+(\?t=.+)?$/;

  // Fetch saved streams for the user
  useEffect(() => {
    if (!user?.uid) return;
    async function fetchStreams() {
      try {
        const streams = await streamerService.getUserStreams(user.uid);
        console.log("Fetched streams:", streams);
        setExistingStreams(Array.isArray(streams) ? streams : []);
      } catch (error) {
        console.error("Error fetching existing streams:", error);
      }
    }
    fetchStreams();
  }, [user]);

  // Fetch stored user info if available
  useEffect(() => {
    if (!user?.uid) return;
    async function fetchUserInfo() {
      try {
        const info = await streamerService.getUserInfo(user.uid);
        if (info) {
          setUserInfo(info);
          setFaceitNickname(info.faceitUsername);
          setTwitchNickname(info.twitchUsername);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    }
    fetchUserInfo();
  }, [user]);

  // Handle verification & retrieving matches
  const handleVerify = async () => {
    if (!faceitNickname.trim() || !twitchNickname.trim()) {
      setVerificationError("Both Faceit and Twitch nicknames are required.");
      return;
    }
    setLoadingVerification(true);
    setVerificationError("");
    try {
      const trimmedNickname = faceitNickname.trim();
      const playerDetails = await faceitService.getPlayerDetails(trimmedNickname);
      if (!playerDetails || !playerDetails.player_id) {
        setVerificationError("Faceit nickname not found.");
        return;
      }
      const gamesList = await faceitService.getPlayerRecentMatches(
        playerDetails.player_id,
        100
      );
      const recentMatches = Array.isArray(gamesList) ? gamesList : [];
      if (recentMatches.length === 0) {
        setVerificationError("No recent games found.");
        return;
      }
      const detailedGamesList = await Promise.all(
        recentMatches
          .slice(0, 10)
          .map((game) => faceitService.getMatchDetails(game.match_id))
      );
      setGames(detailedGamesList);
    } catch (error) {
      console.error("Error verifying Faceit nickname:", error);
      setVerificationError("Error verifying Faceit nickname.");
    } finally {
      setLoadingVerification(false);
    }
  };

  // Toggle matchroom selection
  const toggleMatchroomSelection = (matchId) => {
    setSelectedMatchrooms((prevSelected) =>
      prevSelected.includes(matchId)
        ? prevSelected.filter((id) => id !== matchId)
        : [...prevSelected, matchId]
    );
  };

  // Validate stream URL input
  const handleStreamUrlChange = (matchId, value) => {
    setStreamUrls((prev) => ({
      ...prev,
      [matchId]: value,
    }));
    if (value && !streamUrlRegex.test(value)) {
      setStreamUrlErrors((prev) => ({
        ...prev,
        [matchId]:
          "Invalid format. Expected: https://www.twitch.tv/videos/123456789?t=timestamp",
      }));
    } else {
      setStreamUrlErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[matchId];
        return newErrors;
      });
    }
  };

  // Submit stream URLs for all selected matchrooms
  const handleSubmitStreams = async (e) => {
    e.preventDefault();
    for (const matchId of selectedMatchrooms) {
      if (!streamUrls[matchId] || streamUrls[matchId].trim() === "") {
        setErrorMessage("Please provide a Stream URL for each selected matchroom.");
        return;
      }
      if (streamUrlErrors[matchId]) {
        setErrorMessage("One or more Stream URLs are invalid. Please correct them.");
        return;
      }
    }
    try {
      const results = await Promise.all(
        selectedMatchrooms.map((matchId) => {
          const gameDetails = games.find((game) => game.match_id === matchId);
          return streamerService.createTwitchVod({
            userId: user.uid,
            faceitNickname: faceitNickname.trim(),
            streamerId: twitchNickname.trim(),
            twitchUrl: `https://www.twitch.tv/${twitchNickname.trim()}`,
            matchId,
            streamUrl: streamUrls[matchId].trim(),
            // Store the JSON response (as an object) directly in the streams collection
            matchDetails: gameDetails || {},
          });
        })
      );
      setSuccessMessage(`Streams added successfully. IDs: ${results.join(", ")}`);
      // Reset state
      setFaceitNickname("");
      setTwitchNickname("");
      setGames([]);
      setSelectedMatchrooms([]);
      setStreamUrls({});
      setStep(1);
      // Refresh streams list
      const streams = await streamerService.getStreams(user.uid);
      setExistingStreams(Array.isArray(streams) ? streams : []);
    } catch (error) {
      console.error("Error adding streams:", error);
      setErrorMessage("Failed to add streams. Please try again.");
    }
  };

  // Delete an existing stream
  const handleDeleteStream = async (streamId) => {
    try {
      await streamerService.deleteStream(streamId);
      setExistingStreams((prev) => prev.filter((s) => s.id !== streamId));
    } catch (error) {
      console.error("Error deleting stream:", error);
      setErrorMessage("Failed to delete stream. Please try again.");
    }
  };

  // Helper to format team players
  const formatPlayers = (players) => {
    if (!players || !Array.isArray(players)) return null;
    return players.map((p, idx) => (
      <span key={p.player_id || idx}>
        {p.nickname}
        {idx !== players.length - 1 && ", "}
      </span>
    ));
  };

  // Check if user info can be edited (24-hour rule)
  const isEditable = userInfo
    ? new Date() - new Date(userInfo.updatedAt) > 24 * 60 * 60 * 1000
    : true;

  // Save user info and trigger match retrieval
  const handleSaveUserInfo = async () => {
    if (!faceitNickname.trim() || !twitchNickname.trim()) {
      setErrorMessage("Both Faceit and Twitch nicknames are required.");
      return;
    }
    if (
      userInfo &&
      new Date() - new Date(userInfo.updatedAt) < 24 * 60 * 60 * 1000
    ) {
      setErrorMessage(
        "You cannot update your info until 24 hours have passed since the last update."
      );
      return;
    }
    try {
      await streamerService.saveUserInfo({
        userId: user.uid,
        faceitUsername: faceitNickname.trim(),
        twitchUsername: twitchNickname.trim(),
      });
      const updatedInfo = {
        userId: user.uid,
        faceitUsername: faceitNickname.trim(),
        twitchUsername: twitchNickname.trim(),
        updatedAt: new Date().toISOString(),
      };
      setUserInfo(updatedInfo);
      setEditingUserInfo(false);
      setErrorMessage("");
      // Auto-retrieve matches after saving
      handleVerify();
    } catch (error) {
      console.error("Error saving user info:", error);
      setErrorMessage("Failed to save user info. Please try again.");
    }
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">Streamer Mode</h2>

      {/* Progress Indicator */}
      <div className="flex items-center justify-center mb-6">
        <div className="flex items-center">
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full ${
              step === 1 ? "bg-blue-600" : "bg-gray-600"
            } text-white font-bold`}
          >
            1
          </div>
          <div className="w-16 h-1 bg-gray-600"></div>
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full ${
              step === 2 ? "bg-blue-600" : "bg-gray-600"
            } text-white font-bold`}
          >
            2
          </div>
        </div>
      </div>

      {/* User Information Section */}
      <div className="border border-gray-600 p-4 rounded-lg mb-6 bg-gray-800">
        <h3 className="text-xl font-semibold mb-4">User Information</h3>
        {userInfo && !editingUserInfo ? (
          <div className="space-y-3">
            <p>
              <strong>Faceit Username:</strong> {userInfo.faceitUsername}
            </p>
            <p>
              <strong>Twitch Username:</strong> {userInfo.twitchUsername}
            </p>
            <div className="flex space-x-4">
              <button
                onClick={handleVerify}
                disabled={loadingVerification}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center"
              >
                {loadingVerification && (
                  <svg
                    className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                )}
                Retrieve Matches
              </button>
              <button
                onClick={() => {
                  setEditingUserInfo(true);
                  setErrorMessage("");
                }}
                disabled={!isEditable}
                className={`bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-2 px-4 rounded-lg ${
                  !isEditable ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Edit Info
              </button>
            </div>
            {!isEditable && (
              <p className="text-sm text-gray-300 mt-2">
                You can only update these details after 24 hours since the last update.
              </p>
            )}
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300">
                Faceit Nickname
              </label>
              <input
                type="text"
                value={faceitNickname}
                onChange={(e) => setFaceitNickname(e.target.value)}
                className="mt-1 block w-full bg-gray-700 text-white rounded px-3 py-2"
                placeholder="Enter Faceit nickname"
                required
                disabled={userInfo && !isEditable}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300">
                Twitch Nickname
              </label>
              <input
                type="text"
                value={twitchNickname}
                onChange={(e) => setTwitchNickname(e.target.value)}
                className="mt-1 block w-full bg-gray-700 text-white rounded px-3 py-2"
                placeholder="Enter Twitch channel nickname"
                required
                disabled={userInfo && !isEditable}
              />
            </div>
            <div className="flex space-x-4">
              <button
                onClick={handleSaveUserInfo}
                className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Save & Retrieve Matches
              </button>
              <button
                onClick={() => {
                  setEditingUserInfo(false);
                  setErrorMessage("");
                }}
                className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {verificationError && (
          <p className="text-red-500 text-sm mt-2">{verificationError}</p>
        )}
      </div>

      {/* Step 1: Matchroom Selection */}
      {step === 1 && games.length > 0 && (
        <div className="border border-gray-600 p-4 rounded-lg mb-6 bg-gray-800">
          <h3 className="text-xl font-semibold mb-4">Select Matches</h3>
          <p className="text-sm text-gray-300 mb-4">
            Click on the cards below to select one or more matches where you were streaming.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {games.map((game) => {
              const selectedMap = game.voting?.map?.entities?.find(
                (map) => map.game_map_id === game.voting?.map?.pick?.[0]
              );
              const mapName = selectedMap?.name || "Map TBD";
              const mapImage = selectedMap?.image_lg || selectedMap?.image_sm;
              const isSelected = selectedMatchrooms.includes(game.match_id);
              return (
                <div
                  key={game.match_id}
                  onClick={() => toggleMatchroomSelection(game.match_id)}
                  className={`border rounded-lg p-4 cursor-pointer hover:bg-gray-700 transition-all duration-200 ${
                    isSelected ? "border-blue-500 bg-gray-800" : "border-gray-600 bg-gray-800"
                  }`}
                >
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center space-x-3">
                      <img
                        src={game.teams.faction2.avatar}
                        alt={game.teams.faction2.nickname}
                        className="w-10 h-10 rounded-full"
                      />
                      <div>
                        <p className="font-bold text-white">
                          {game.teams.faction2.nickname}
                        </p>
                        <p className="text-gray-300 text-xs">
                          {formatPlayers(game.teams.faction2.roster)}
                        </p>
                      </div>
                    </div>
                    {isSelected && (
                      <div className="flex items-center justify-center w-6 h-6 bg-blue-600 rounded-full">
                        <svg
                          className="w-4 h-4 text-white"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="text-center mb-3">
                    <span
                      className={`font-bold text-lg ${
                        game.results.winner === "faction2" ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {game.results.winner === "faction2" ? "W" : "L"}
                    </span>{" "}
                    :{" "}
                    <span
                      className={`font-bold text-lg ${
                        game.results.winner === "faction1" ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {game.results.winner === "faction1" ? "W" : "L"}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <img
                        src={game.teams.faction1.avatar}
                        alt={game.teams.faction1.nickname}
                        className="w-10 h-10 rounded-full"
                      />
                      <div>
                        <p className="font-bold text-white">
                          {game.teams.faction1.nickname}
                        </p>
                        <p className="text-gray-300 text-xs">
                          {formatPlayers(game.teams.faction1.roster)}
                        </p>
                      </div>
                    </div>
                    {mapImage && (
                      <div className="relative">
                        <img
                          src={mapImage}
                          alt={mapName}
                          className="w-20 h-14 rounded"
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                          <span className="bg-white bg-opacity-50 text-gray-950 font-bold text-xs px-1 rounded">
                            {mapName}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-center text-gray-400 text-xs mt-3">
                    {new Date(game.started_at * 1000).toLocaleString()}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => {
                if (selectedMatchrooms.length === 0) {
                  setErrorMessage("Please select at least one matchroom.");
                  return;
                }
                setErrorMessage("");
                setStep(2);
              }}
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-lg"
            >
              Next
            </button>
          </div>
          {errorMessage && (
            <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
          )}
        </div>
      )}

      {/* Step 2: Stream URL Submission */}
      {step === 2 && (
        <div className="border border-gray-600 p-4 rounded-lg mb-6 bg-gray-800">
          <h3 className="text-xl font-semibold mb-4">Provide Stream URLs</h3>
          <p className="text-sm text-gray-300 mb-4">
            Enter the full Twitch VOD URL for each selected matchroom. It should be in the format:{" "}
            <code className="bg-gray-700 px-1 py-0.5 rounded">
              https://www.twitch.tv/videos/123456789?t=timestamp
            </code>
          </p>
          <p className="text-sm text-red-500 mb-4">
            Warning: Spamming this feature may result in a permanent ban.
          </p>
          {selectedMatchrooms.map((matchId) => {
            const game = games.find((g) => g.match_id === matchId);
            const selectedMap = game?.voting?.map?.entities?.find(
              (map) => map.game_map_id === game?.voting?.map?.pick?.[0]
            );
            const mapName = selectedMap?.name || "Map TBD";
            return (
              <div
                key={matchId}
                className="border border-gray-600 rounded-lg p-4 mb-4 bg-gray-700"
              >
                <div className="flex items-center justify-between mb-2">
                  <h4 className="font-bold text-white">
                    Matchroom: {matchId} - {mapName}
                  </h4>
                  {game && (
                    <a
                      href={`https://www.faceit.com/en/cs2/room/${matchId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:underline text-sm"
                    >
                      {new Date(game.started_at * 1000).toLocaleString()}
                    </a>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300">
                    Stream URL
                  </label>
                  <input
                    type="text"
                    value={streamUrls[matchId] || ""}
                    onChange={(e) =>
                      handleStreamUrlChange(matchId, e.target.value)
                    }
                    className="mt-1 block w-full bg-gray-800 text-white rounded px-3 py-2 border border-gray-600"
                    placeholder="Enter full Twitch VOD URL"
                    required
                  />
                  {streamUrlErrors[matchId] && (
                    <p className="text-red-500 text-sm mt-1">
                      {streamUrlErrors[matchId]}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => setStep(1)}
              className="mt-4 text-blue-400 hover:text-blue-500 text-sm"
            >
              Back
            </button>
            <button
              onClick={handleSubmitStreams}
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg mt-4"
            >
              Add Streams
            </button>
          </div>
          {successMessage && (
            <p className="mt-4 text-green-500 text-sm">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="mt-4 text-red-500 text-sm">{errorMessage}</p>
          )}
        </div>
      )}

      {/* Existing Streams Section */}
      <div className="border border-gray-600 p-4 rounded-lg bg-gray-800">
        <h3 className="text-xl font-semibold mb-4">Your Past Streams</h3>
        {existingStreams.length === 0 ? (
          <p className="text-gray-300">No streams available.</p>
        ) : (
          <div className="space-y-4">
            {existingStreams.map((stream) => (
              <div
                key={stream.id}
                className="border border-gray-600 rounded-lg p-4 bg-gray-700 flex flex-col md:flex-row md:justify-between items-start"
              >
                <div className="mb-3 md:mb-0">
                  <p className="text-white font-medium">
                    <a
                      href={`https://www.faceit.com/en/cs2/room/${stream.matchroomId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      Matchroom: {stream.matchroomId}
                    </a>
                  </p>
                  {stream.createdAt && (
                    <p className="text-gray-300 text-sm">
                      {new Date(stream.createdAt).toLocaleString()}
                    </p>
                  )}
                  <p className="text-gray-300 text-sm">
                    Faceit: {stream.faceitNickname} | Twitch: {stream.streamerId}
                  </p>
                  <p className="text-gray-300 text-sm">
                    Twitch URL: {stream.twitchUrl}
                  </p>
                  <p className="text-gray-300 text-sm">
                    Stream URL: {stream.streamUrl}
                  </p>
                </div>
                <button
                  onClick={() => handleDeleteStream(stream.id)}
                  className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1 px-3 rounded self-end"
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default StreamerModeTab;
