// chathn_new/src/services/faceitService.js
const FACEIT_API_KEY = process.env.REACT_APP_FACEIT_API_KEY;
const BASE_URL = "https://open.faceit.com/data/v4";
const POINTS_PER_WIN = 12;
const POINTS_PER_LOSS = -8;

const headers = {
  Authorization: `Bearer ${FACEIT_API_KEY}`,
  Accept: "application/json",
};

// Simple in-memory caches
const matchDetailsCache = new Map();
const playerRecentMatchesCache = new Map();

const determineMatchResult = (match, playerId) => {
  let playerFaction = null;
  if (match.teams.faction1.players.some((p) => p.player_id === playerId)) {
    playerFaction = "faction1";
  } else if (
    match.teams.faction2.players.some((p) => p.player_id === playerId)
  ) {
    playerFaction = "faction2";
  }
  return playerFaction === match.results.winner ? "1" : "0";
};

const calculateEloChange = (matchResult) => {
  return matchResult === "1" ? POINTS_PER_WIN : POINTS_PER_LOSS;
};

export const faceitService = {
  async getPlayerDetails(nickname) {
    try {
      const response = await fetch(`${BASE_URL}/players?nickname=${nickname}`, {
        headers,
      });
      if (response.status === 404) {
        console.warn(`Player not found: ${nickname}`);
        return null; // Return null for non-existent players
      }
      if (!response.ok) throw new Error("Failed to fetch player details");
      return await response.json();
    } catch (error) {
      console.error("Error fetching player details:", error);
      return null;
    }
  },

  async getPlayerStats(playerId) {
    try {
      const response = await fetch(
        `${BASE_URL}/players/${playerId}/stats/cs2`,
        { headers }
      );
      if (!response.ok) throw new Error("Failed to fetch player stats");
      return await response.json();
    } catch (error) {
      console.error("Error fetching player stats:", error);
      return null;
    }
  },

  async getPlayerHistoryStats(playerId, fromDate, toDate) {
    try {
      const from = Math.floor(fromDate.getTime() / 1000);
      const to = Math.floor(toDate.getTime() / 1000);

      const weeklyUrl = `${BASE_URL}/players/${playerId}/history?from=${from}&to=${to}&game=cs2&limit=100`;
      const recentUrl = `${BASE_URL}/players/${playerId}/history?game=cs2&offset=0&limit=10`;

      const [weeklyResponse, recentResponse] = await Promise.all([
        fetch(weeklyUrl, { headers }),
        fetch(recentUrl, { headers }),
      ]);

      if (!weeklyResponse.ok)
        throw new Error("Failed to fetch player weekly history");
      if (!recentResponse.ok)
        throw new Error("Failed to fetch player recent history");

      const [weeklyData, recentData] = await Promise.all([
        weeklyResponse.json(),
        recentResponse.json(),
      ]);

      const weeklyMatches = weeklyData.items || [];
      const weeklyResults = weeklyMatches.map((match) =>
        determineMatchResult(match, playerId)
      );
      const weeklyWins = weeklyResults.filter(
        (result) => result === "1"
      ).length;
      const totalWeeklyMatches = weeklyResults.length;

      const weeklyWinRate =
        totalWeeklyMatches > 0
          ? ((weeklyWins / totalWeeklyMatches) * 100).toFixed(1)
          : "0";

      const weeklyEloChange = weeklyResults.reduce((total, result) => {
        return total + calculateEloChange(result);
      }, 0);

      const recentResults = (recentData.items || []).map((match) =>
        determineMatchResult(match, playerId)
      );

      const lastActivityTimestamp = recentData.items?.[0]?.started_at;
      const lastActivity = lastActivityTimestamp
        ? new Date(lastActivityTimestamp * 1000)
        : null;

      return {
        matches: totalWeeklyMatches.toString(),
        winRate: weeklyWinRate,
        eloChange: weeklyEloChange,
        results: recentResults,
        weeklyWins: weeklyWins.toString(),
        weeklyLosses: (totalWeeklyMatches - weeklyWins).toString(),
        lastActivity,
      };
    } catch (error) {
      console.error("Error fetching player history:", error);
      return {
        matches: "0",
        winRate: "0",
        eloChange: 0,
        results: [],
        weeklyWins: "0",
        weeklyLosses: "0",
        lastActivity: null,
      };
    }
  },

  async getPlayerRecentMatches(playerId, limit = 100) {
    const cacheKey = `${playerId}-${limit}`;
    if (playerRecentMatchesCache.has(cacheKey)) {
      return playerRecentMatchesCache.get(cacheKey);
    }
    try {
      const response = await fetch(
        `${BASE_URL}/players/${playerId}/history?game=cs2&offset=0&limit=${limit}`,
        { headers }
      );
      if (!response.ok)
        throw new Error("Failed to fetch player recent matches");
      const data = await response.json();
      const items = Array.isArray(data.items) ? data.items : [];
      playerRecentMatchesCache.set(cacheKey, items);
      return items;
    } catch (error) {
      console.error("Error fetching player recent matches:", error);
      return [];
    }
  },

  async getMatchDetails(matchId) {
    if (matchDetailsCache.has(matchId)) {
      return matchDetailsCache.get(matchId);
    }
    try {
      const response = await fetch(`${BASE_URL}/matches/${matchId}`, {
        headers,
      });
      if (!response.ok) throw new Error("Failed to fetch match details");
      const data = await response.json();
      matchDetailsCache.set(matchId, data);
      return data;
    } catch (error) {
      console.error("Error fetching match details:", error);
      return null;
    }
  },
};
