import React, { useState } from "react";
import Header from "../components/Header";
import LeaguesTab from "./LeaguesTab";
import StreamerModeTab from "./StreamerModeTab";

function Dashboard() {
  const [activeTab, setActiveTab] = useState("leagues");

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <div className="max-w-7xl mx-auto py-10">
        <div className="mb-6 flex">
          <button
            onClick={() => setActiveTab("leagues")}
            className={`px-4 py-2 rounded-l-lg ${
              activeTab === "leagues" ? "bg-blue-600" : "bg-gray-700"
            }`}
          >
            Leagues
          </button>
          <button
            onClick={() => setActiveTab("streamer")}
            className={`px-4 py-2 rounded-r-lg ${
              activeTab === "streamer" ? "bg-blue-600" : "bg-gray-700"
            }`}
          >
            Streamer Mode
          </button>
        </div>
        {activeTab === "leagues" && <LeaguesTab />}
        {activeTab === "streamer" && <StreamerModeTab />}
      </div>
    </div>
  );
}

export default Dashboard;
