import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { leagueService } from "../services/leagueService";
import {
  FaTrophy,
  FaSmile,
  FaChartLine,
  FaSadTear,
  FaSearch,
  FaTwitch,
  FaInfoCircle,
} from "react-icons/fa";
import Header from "../components/Header";
import { faceitService } from "../services/faceitService";
import avatarFallback from "../assets/images/avatar-fallback.png";
// Import Firestore functions for dynamic streamed matches fetching
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { db } from "../firebase";

function Login() {
  const { user, signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchError, setSearchError] = useState("");
  const [recentLeagues, setRecentLeagues] = useState([]);
  const [favoriteLeagues, setFavoriteLeagues] = useState([]);
  const [loading, setLoading] = useState(true);

  // Highlighted streamer state initialized with fallback image
  const [highlightedStreamer, setHighlightedStreamer] = useState({
    name: "pilafmeister",
    avatar: avatarFallback,
    twitchUrl: "https://twitch.tv/pilafmeister",
  });

  // Dynamic streamed matches will be fetched from the "streams" Firestore collection.
  const [streamedMatches, setStreamedMatches] = useState([]);

  useEffect(() => {
    fetchRecentLeagues();
    loadFavoriteLeagues();
  }, []);

  // Fetch the highlighted streamer's avatar using his Faceit nickname
  useEffect(() => {
    async function fetchHighlightedAvatar() {
      try {
        const details = await faceitService.getPlayerDetails("pilafmeister");
        setHighlightedStreamer((prev) => ({
          ...prev,
          avatar: details?.avatar || avatarFallback,
        }));
      } catch (error) {
        console.error("Error fetching highlighted streamer avatar:", error);
      }
    }
    fetchHighlightedAvatar();
  }, []);

  // Fetch streamed matches and update each stream object with its avatar
  useEffect(() => {
    async function fetchStreamedMatches() {
      try {
        const streamsQuery = query(
          collection(db, "streams"),
          orderBy("createdAt", "desc"),
          limit(10)
        );
        const querySnapshot = await getDocs(streamsQuery);
        const streamsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const streamerNickname = data.streamerFaceitNickname;
            const playerDetails = await faceitService.getPlayerDetails(streamerNickname);
            const streamerAvatar =
              playerDetails && playerDetails.avatar
                ? playerDetails.avatar
                : avatarFallback;
            // Return all data including matchDetails
            return { id: doc.id, ...data, streamerAvatar };
          })
        );
        setStreamedMatches(streamsData);
      } catch (error) {
        console.error("Error fetching streamed matches:", error);
      }
    }
    fetchStreamedMatches();
  }, []);

  const fetchRecentLeagues = async () => {
    try {
      const leagues = await leagueService.getRecentLeagues();
      const validLeagues = leagues.filter((league) => league.players.length >= 1);
      const leaguesWithAvatars = await Promise.all(
        validLeagues.map(async (league) => {
          const playersWithAvatars = await Promise.all(
            league.players.map(async (playerNickname) => {
              const playerDetails = await faceitService.getPlayerDetails(playerNickname);
              if (!playerDetails) return null;
              const avatarUrl = playerDetails.avatar || avatarFallback;
              return { nickname: playerNickname, avatarUrl };
            })
          );
          const validPlayers = playersWithAvatars.filter(Boolean);
          return { ...league, players: validPlayers };
        })
      );
      setRecentLeagues(leaguesWithAvatars);
    } catch (error) {
      console.error("Error fetching recent leagues:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadFavoriteLeagues = async () => {
    const favorites = JSON.parse(localStorage.getItem("favoriteLeagues")) || [];
    const leagues = [];
    for (const leagueId of favorites) {
      try {
        const league = await leagueService.getLeague(leagueId);
        if (league && league.players.length >= 1) {
          leagues.push(league);
        }
      } catch (error) {
        console.error("Error loading favorite league:", error);
      }
    }
    setFavoriteLeagues(leagues);
  };

  // Search function using league name
  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchError("");

    if (!searchQuery.trim()) {
      setSearchError("Please enter a league name");
      return;
    }

    try {
      const league = await leagueService.searchLeagueByName(searchQuery.trim());
      if (league) {
        navigate(`/league/${league.id}`);
        setSearchQuery("");
      } else {
        setSearchError("League not found");
      }
    } catch (error) {
      setSearchError("Error finding league");
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center">
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <section
        className="w-full bg-cover bg-center py-20"
        style={{ backgroundImage: "url('/hero-bg.jpg')" }}
      >
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Track Your Faceit Friends Effortlessly
          </h1>
          <p className="text-xl text-gray-200 mb-8">
            Join the community, compete with friends, and climb the leaderboards.
          </p>
          <button
            onClick={() => (user ? navigate("/dashboard") : signInWithGoogle())}
            className="bg-orange-500 hover:bg-orange-600 text-white px-8 py-4 rounded-full font-semibold text-xl transition duration-200"
          >
            Create a League Now
          </button>
        </div>
      </section>

      {/* How It Works Section */}
      <div className="mt-16 mb-24 px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-100">
          How It Works
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: <FaTrophy className="w-12 h-12 text-orange-500 mb-4" />,
              title: "League Tracking",
              description: "Sign in, create, and track leagues easily.",
            },
            {
              icon: <FaSmile className="w-12 h-12 text-yellow-400 mb-4" />,
              title: "Emoji Prizes",
              description: "Earn emojis as prizes for bragging rights.",
            },
            {
              icon: <FaChartLine className="w-12 h-12 text-green-500 mb-4" />,
              title: "Point System",
              description: "Win = 12 pts, Loss = 8 pts. Keep climbing!",
            },
            {
              icon: <FaSadTear className="w-12 h-12 text-blue-500 mb-4" />,
              title: "Hall of Shame",
              description: "Lowest scorer gets a special mention.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-gray-800 p-8 rounded-2xl text-center shadow-lg hover:shadow-2xl transition duration-300"
            >
              {item.icon}
              <h3 className="font-semibold text-2xl mb-4 text-gray-200">
                {item.title}
              </h3>
              <p className="text-md text-gray-400">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Search and Leagues Section */}
      <div className="w-full max-w-6xl grid grid-cols-1 lg:grid-cols-2 gap-6 px-4 mb-24">
        {/* Left Container: Search and Leagues */}
        <div className="flex flex-col items-center bg-gray-800 px-4 pb-4 rounded-xl shadow-lg w-full">
          {/* Favorite Leagues Section */}
          <h3 className="text-md text-orange-600 font-bold py-4 text-gray-100 text-left w-full">
            My Favorited Leagues
          </h3>
          {favoriteLeagues.length > 0 ? (
            <div className="w-full space-y-1.5 mb-6">
              {favoriteLeagues.map((league) => (
                <div
                  key={league.id}
                  className="bg-gray-700/20 px-4 py-2 rounded-lg cursor-pointer transition duration-200 hover:bg-gray-600/50 w-full"
                  onClick={() => navigate(`/league/${league.id}`)}
                >
                  <h4 className="text-sm font-bold mb-2">{league.name}</h4>
                  <p className="text-gray-400 text-xs">
                    Players: {league.players.length}
                  </p>
                  <p className="text-gray-500 text-xs">
                    Created on: {new Date(league.createdAt).toLocaleDateString()}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-400 mb-6">No favorite leagues added.</p>
          )}

          {/* Search Form */}
          <form onSubmit={handleSearch} className="flex flex-col gap-4 w-full py-4">
            <div className="flex w-full gap-2">
              <div className="relative flex-grow">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search for Leagues by name..."
                  className="w-full px-5 py-2 rounded-xl bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <FaSearch className="absolute right-4 top-4 text-gray-400" />
              </div>
              <button
                type="submit"
                className="bg-orange-500 hover:bg-orange-600 text-white px-6 py-2 rounded-xl font-semibold transition duration-200 whitespace-nowrap"
              >
                Search
              </button>
            </div>
            {searchError && (
              <p className="text-red-500 text-sm text-center">{searchError}</p>
            )}
          </form>

          {/* Recent Leagues Section */}
          <h3 className="text-md text-orange-600 font-bold py-2 text-gray-100 text-left w-full">
            Recent Leagues
          </h3>
          {loading ? (
            <div className="text-center">Loading...</div>
          ) : recentLeagues.length > 0 ? (
            <div className="w-full space-y-1.5">
              {recentLeagues.map((league) => (
                <div
                  key={league.id}
                  className="bg-gray-700/20 px-4 py-2 rounded-lg shadow-md cursor-pointer transition duration-200 hover:bg-gray-600/50 w-full"
                  onClick={() => navigate(`/league/${league.id}`)}
                >
                  <h4 className="text-sm font-bold mb-2">{league.name}</h4>
                  <div className="flex -space-x-3 overflow-hidden">
                    {league.players.slice(0, 9).map((player, index) => (
                      <div key={index} className="relative group flex-shrink-0">
                        <img
                          src={player.avatarUrl}
                          alt={player.nickname}
                          className="inline-block h-8 w-8 rounded-full"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = avatarFallback;
                          }}
                        />
                        <span className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 opacity-0 group-hover:opacity-100 bg-black text-white text-xs rounded px-2 py-1 whitespace-nowrap transition-opacity duration-200 z-10">
                          {player.nickname}
                        </span>
                      </div>
                    ))}
                    {league.players.length > 9 && (
                      <div className="z-0 h-8 w-8 rounded-full bg-gray-800 flex items-center justify-center text-gray-300 font-bold text-xs">
                        +{league.players.length - 9}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-400">No recent leagues available.</p>
          )}
        </div>

        {/* Right Container: Highlighted Streamer and Streamed Matches */}
        <div className="flex flex-col items-center bg-gray-800 px-4 pb-4 rounded-xl shadow-lg w-full">
          {/* Highlighted Streamer Section */}
          <div className="w-full mb-6">
            <h3 className="text-md text-purple-600 font-bold py-4 text-gray-100 text-left w-full">
              Highlighted Streamer
            </h3>
            <div className="flex items-center bg-gray-700 p-4 rounded-lg shadow-md">
              <img
                src={highlightedStreamer.avatar}
                alt={highlightedStreamer.name}
                className="w-16 h-16 rounded-full mr-4"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avatarFallback;
                }}
              />
              <div>
                <h4 className="text-lg font-bold flex items-center">
                  <FaTwitch className="w-6 h-6 mr-2 text-purple-600" />
                  {highlightedStreamer.name}
                </h4>
                <a
                  href={highlightedStreamer.twitchUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline text-sm"
                >
                  Follow on Twitch
                </a>
              </div>
            </div>
          </div>

          {/* Last Streamed Matches Section */}
          <div className="w-full">
            <div className="flex items-center justify-between w-full py-4">
              <h3 className="text-md text-purple-600 font-bold text-gray-100">
                Streamer Alert
              </h3>
              <div className="relative group cursor-pointer">
                <FaInfoCircle className="text-blue-400 hover:text-blue-500 w-5 h-5 transition duration-200" />
                <span className="absolute right-0 top-full mt-2 w-max bg-black text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10">
                  Add your streams: Admin &gt; Streamer Mode
                </span>
              </div>
            </div>
            {streamedMatches.map((match) => {
              // Extract the scores for faction1 and faction2 using the specified structure
              const detailedResults = match.matchDetails?.detailed_results;
              const firstResult = detailedResults[0];
              const faction1Score = firstResult.factions?.faction1?.score;
              const faction2Score = firstResult.factions?.faction2?.score;
              console.log(match.matchDetails);
              return (
                <div
                  key={match.id}
                  className="flex items-center bg-gray-700 p-4 rounded-lg shadow-md hover:bg-gray-600 transition duration-200 mb-1.5"
                >
                  <div className="flex-grow">
                    <h4 className="text-md font-bold">
                      {
                        match.matchDetails?.voting?.map?.entities?.[0]?.game_map_id || match.mapName
                      } <span className="ml-2 text-gray-500 text-xs"> {match.matchDetails
                        ? new Date(match.matchDetails.finished_at * 1000).toLocaleString()
                        : new Date(match.matchDate).toLocaleString()}</span>
                    </h4>
                    <p className="text-gray-400 text-md">
                      Streamed by: {match.streamerId ||
                        `${match.faction1} vs ${match.faction2}`}
                    </p>
                    {match.matchDetails && match.matchDetails.teams && (
                      <div className="mt-2 text-sm flex space-x-4">
                        <span>
                          {match.matchDetails.teams.faction1?.name || "N/A"}
                          {faction1Score === 1 ? (
                            <span className="text-green-500 font-bold ml-1">W</span>
                          ) : faction1Score === 0 ? (
                            <span className="text-red-500 font-bold ml-1">L</span>
                          ) : null}
                        </span>
                        <span>
                          {match.matchDetails.teams.faction2?.name || "N/A"}
                          {faction2Score === 1 ? (
                            <span className="text-green-500 font-bold ml-1">W</span>
                          ) : faction2Score === 0 ? (
                            <span className="text-red-500 font-bold ml-1">L</span>
                          ) : null}
                        </span>
                      </div>
                    )}
                  </div>
                  <a
                    href={match.streamUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-purple-600 hover:bg-purple-700 text-white p-2 rounded flex items-center justify-center shadow-md transition duration-200"
                  >
                    <FaTwitch className="w-6 h-6 text-white" />
                  </a>

                </div>
              );
            })}

          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="w-full bg-gray-800 py-6">
        <div className="max-w-7xl mx-auto text-center text-gray-400">
          © {new Date().getFullYear()} FaceitReload. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default Login;
